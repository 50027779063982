<template>
    <div class="page_content">
        <div class="page_edit_content">
            <div class="steps">
                <a-steps :current="steps_current">
                    <a-step
                        v-for="item in steps"
                        :key="item.title"
                        :title="item.title"
                    />
                </a-steps>
                <a-alert
                    class="alert"
                    show-icon
                    v-if="alert_visible"
                    message="当未设置或不符合条件审批流程时，按通用审批流程执行"
                    type="info"
                    closable
                    :after-close="handleClose"
                />
            </div>

            <div v-show="steps_current === 0">
                <div class="box_form approval_title">
                    <Form ref="form" :form_data="form_data"></Form>
                </div>

                <div class="box_form approval_title">
                    <a-table
                        :columns="columns"
                        rowKey="flag"
                        :pagination="false"
                        :dataSource="list"
                        :row-selection="approval_selection"
                    >
                    </a-table>
                </div>
            </div>

            <div v-show="steps_current === 1">
                <div class="h1">
                    <div class="title">默认审批人</div>
                </div>
                <a-timeline class="time_line">
                    <a-timeline-item
                        class="li"
                        :class="[index == approval.length - 1 ? 'last_li' : '']"
                        v-for="(item, index) in approval"
                    >
                        <div
                            class="item"
                            :draggable="true"
                            @dragstart="dragstart($event, -1, index)"
                            @dragover="dragover"
                            @dragenter="dragenter($event, -1, index)"
                            @dragend="dragend($event, -1, index)"
                            @drop="drop"
                        >
                            <div class="title">
                                <div
                                    class="tit"
                                    v-for="item_title in approval_type_list"
                                    v-if="item.type == item_title.value"
                                >
                                    <i
                                        class="iconfont icon drag icontuozhuai"
                                    ></i>
                                    {{ item_title.label }}
                                    <template
                                        v-for="manner in approval_manner_list"
                                    >
                                        <span
                                            class="tit_info"
                                            v-if="manner.value == item.mode"
                                            >({{ manner.label }})</span
                                        >
                                    </template>
                                    <i
                                        @click="edit_approval(item, index)"
                                        class="iconfont icon main_color iconbianji"
                                    ></i>
                                    <i
                                        @click="delete_approval(item, index)"
                                        class="iconfont icon iconshanchu"
                                    ></i>
                                </div>
                            </div>
                            <div class="item_ul" v-if="item.user_data">
                                <div
                                    class="avatar"
                                    :draggable="true"
                                    @dragstart="dragstart($event, ind, index)"
                                    @dragover="dragover"
                                    @dragenter="dragenter($event, ind, index)"
                                    @dragend="dragend($event, ind, index)"
                                    @drop="drop"
                                    v-for="(vo, ind) in item.user_data"
                                    :key="ind"
                                >
                                    <Avatar
                                        :show_name="true"
                                        :value="vo.username"
                                        :avatar_name="
                                            vo.type == 'role'
                                                ? '角色'
                                                : vo.type == 'director'
                                                ? '负责人'
                                                : vo.username
                                        "
                                    ></Avatar>
                                </div>
                            </div>
                        </div>
                    </a-timeline-item>
                    <a-timeline-item class="li li_add">
                        <div class="item item_add">
                            <div class="add" @click="add">
                                <Add></Add>
                            </div>
                        </div>
                    </a-timeline-item>
                </a-timeline>
                <div class="h1">
                    <a-divider />
                    <div class="title" style="padding-top: 12px">
                        默认抄送人
                    </div>
                    <div class="info">
                        根据抄送通知设置，审批发起时或审批通过后自动通知抄送人
                    </div>

                    <div class="item_ul">
                        <div
                            class="avatar"
                            v-for="(vo, ind) in cc_data.user_data"
                            :key="ind"
                        >
                            <Avatar
                                :show_close="true"
                                @close="del_cc_user(vo, ind)"
                                :show_name="true"
                                :value="vo.username"
                                :avatar_name="
                                    vo.type == 'role'
                                        ? '角色'
                                        : vo.type == 'director'
                                        ? '负责人'
                                        : vo.username
                                "
                            ></Avatar>
                        </div>
                        <div
                            class="add"
                            :class="[cc_data.user_data.length ? 'avatar' : '']"
                            @click="add_cc"
                        >
                            <Add></Add>
                        </div>
						<div class="rule_user_select">
							<a-divider />
							<div class="title">选择适用人员</div>
							<TransferTree
								@change="TransferTreeChange"
								:treeData="treeData"
								:value="treeDataValue"
							/>
						</div>
                    </div>
                </div>
            </div>

            <div v-show="steps_current === 2">
                <div class="box_form approval_title">
                    <Form
                        ref="form_advanced"
                        @handleChange="handleChange"
                        :form_data="form_data_advanced"
                    >
                        <div slot="appoint_id">
                            {{ handler_user.username }}
                            <a-button
                                class="btn"
                                @click="select_handler"
                                type="primary"
                                >{{
                                    handler_user.id ? "重新" : ""
                                }}选择处理人</a-button
                            >
                        </div>
						<template slot="rule_user">
							<a-input hidden="true" v-decorator="['rule_user']"></a-input>
						</template>
                    </Form>
                </div>
            </div>

            <div class="box_form box_form_btns">
                <a-button
                    v-if="steps_current != 0"
                    class="btn"
                    type="defalut"
                    @click="prev"
                    style="margin-right: 10px"
                    >上一步</a-button
                >
                <a-button class="btn" type="primary" @click="next">{{
                    steps_current == 2 ? "完成" : "下一步"
                }}</a-button>
            </div>
        </div>
        <!-- 选择审批人 -->
        <RightDrawer width="540px" title="选择审批人" :visible.sync="visible">
            <template slot="content">
                <div class="select_pop">
                    <div class="title">审批人</div>
                    <div class="content">
                        <a-radio-group
                            v-model="approval_type_value"
                            @change="approval_type_change"
                        >
                            <a-radio
                                :value="item.value"
                                class="radio"
                                v-for="item in approval_type_list"
                            >
                                <a-tooltip
                                    title="选择同类型员工作为审批人，如行政、人事、财务等部门"
                                >
                                    <a-icon type="question-circle" />
                                </a-tooltip>
                                {{ item.label }}
                            </a-radio>
                        </a-radio-group>

                        <template v-if="approval_type_value == 'director'">
                            <a-divider />
                            <div class="h2">选择部门负责人</div>
                            <a-select
                                class="select"
                                v-model="principal_level_value"
                            >
                                <a-select-option
                                    v-for="option in principal_level"
                                    :value="option.level"
                                >
                                    {{ option.label }}
                                </a-select-option>
                            </a-select>
                        </template>
                        <template v-else>
                            <template v-if="approval_type_value == 'optional'">
                                <a-divider />
                                <div class="h2">可选范围</div>
                                <a-radio-group
                                    v-model="approval_optional_type_list_value"
                                >
                                    <a-radio
                                        :value="item.value"
                                        class="radio"
                                        v-for="item in approval_optional_type_list"
                                    >
                                        {{ item.label }}
                                    </a-radio>
                                </a-radio-group>
                            </template>
                            <template
                                v-if="
                                    approval_type_value == 'appoint' ||
                                    (approval_type_value == 'optional' &&
                                        approval_optional_type_list_value ==
                                            'user')
                                "
                            >
                                <a-divider />
                                <div class="h2">选择员工</div>
                                <a-button
                                    @click="select_user"
                                    class="select_btn"
                                    type="primary"
                                    >选择员工</a-button
                                >
                                <div class="selectd_user">
                                    您已选<span class="main_color">{{
                                        selectd_user_text
                                    }}</span
                                    >{{ selectd_user.length }}位员工
                                </div>
                            </template>
                            <template
                                v-if="
                                    approval_type_value == 'role' ||
                                    (approval_type_value == 'optional' &&
                                        approval_optional_type_list_value ==
                                            'role')
                                "
                            >
                                <a-divider />
                                <div class="h2">选择角色</div>
                                <a-select
                                    class="select"
                                    v-model="role_list_value"
                                    @change="role_list_change"
                                    placeholder="请选择角色"
                                    mode="multiple"
                                >
                                    <a-select-option
                                        v-for="option in role_list"
                                        :value="option.key"
                                    >
                                        {{ option.value }}
                                    </a-select-option>
                                </a-select>
                            </template>

                            <a-divider />
                            <div class="h2">审批方式</div>
                            <a-radio-group v-model="approval_manner_list_value">
                                <a-radio
                                    :value="item.value"
                                    class="radio block"
                                    v-if="
                                        (approval_type_value !== 'appoint' &&
                                            item.value !== 'order') ||
                                        approval_type_value === 'appoint'
                                    "
                                    v-for="item in approval_manner_list"
                                >
                                    {{ item.label }} {{ item.info }}
                                </a-radio>
                            </a-radio-group>
                        </template>
                    </div>
                </div>
            </template>
            <template slot="btns">
                <a-button
                    type="defalut"
                    @click="visible = false"
                    style="margin-right: 10px"
                    >取消</a-button
                >
                <a-button type="primary" @click="enter_add_approval"
                    >确定</a-button
                >
            </template>
        </RightDrawer>
        <!-- 选择抄送人 -->
        <RightDrawer
            width="540px"
            title="选择抄送人"
            :visible.sync="visible_cc"
        >
            <template slot="content">
                <div class="h2">选择部门负责人</div>
                <div class="h2_info">
                    实际发起按审批所在部门的主管自动设定抄送人。多次添加主管后将自动把组织架构上各层级的部门负责人、分管领导添加为抄送人
                </div>
                <a-select
                    class="select"
                    placeholder="请选择负责人"
                    v-model="principal_level_value"
                >
                    <a-select-option
                        v-for="option in principal_level"
                        :value="option.level"
                    >
                        {{ option.label }}
                    </a-select-option>
                </a-select>
                <template>
                    <a-divider />
                    <div class="h2">选择角色</div>
                    <div class="h2_info">
                        选择同类型员工作为抄送人，如行政、人事、财务、研发等
                    </div>
                    <a-select
                        class="select"
                        v-model="role_list_value"
                        @change="role_list_change"
                        placeholder="请选择角色"
                        mode="multiple"
                    >
                        <a-select-option
                            v-for="option in role_list"
                            :value="option.key"
                        >
                            {{ option.value }}
                        </a-select-option>
                    </a-select>
                </template>
                <a-divider />
                <template>
                    <div class="h2">选择员工</div>
                    <div class="h2_info">
                        选择特定的企业成员作为抄送人，如某个员工、某个部门负责人或者财务总监、老板等
                    </div>
                    <a-button
                        @click="select_user"
                        class="select_btn"
                        type="primary"
                        >选择员工</a-button
                    >
                    <div class="selectd_user">
                        您已选<span class="main_color">{{
                            selectd_user_text
                        }}</span
                        >{{ selectd_user.length }}位员工
                    </div>
                </template>
            </template>
            <template slot="btns">
                <a-button
                    type="defalut"
                    @click="visible_cc = false"
                    style="margin-right: 10px"
                    >取消</a-button
                >
                <a-button type="primary" @click="enter_add_cc">确定</a-button>
            </template>
        </RightDrawer>
        <SelectUser
            ref="select_user"
            @submit="add_user_submit"
            :visible.sync="select_user_visible"
            select_type="checkbox"
        ></SelectUser>
        <SelectUser
            ref="select_handler"
            @submit="add_handler_user_submit"
            :visible.sync="select_handler_user_visible"
        ></SelectUser>
    </div>
</template>

<script>
import Add from "@/components/small/Add";
import Avatar from "@/components/small/Avatar";
import RightDrawer from "@/components/RightDrawer";
import SelectUser from "@/components/SelectUser";
import Form from "@/components/Form";
import TableList from "@/components/TableList";
import { formList, addFlow, getFlowInfo } from '@/api/approval'
import { post_user } from '@/api/api'
import { authInfo } from '@/api/system'
import TransferTree from "@/components/TransferTree";

const columns = [
    {
        title: "ID",
        dataIndex: "id",
    },
    {
        title: "审批表单名称",
        dataIndex: "name",
    },
];
export default {
    components: {
        Add,
        Avatar,
        RightDrawer,
        SelectUser,
        Form,
        TableList,
		TransferTree

    },
    data() {
        return {
			treeData: [],
			treeDataValue: [],
            alert_visible: true,
            visible: false,
            visible_index: -1,
            visible_cc: false,
            select_user_visible: false,
            select_handler_user_visible: false,
            handler_user: {},
            steps_current: 0,
            steps: [
                {
                    title: '基本信息',
                },
                {
                    title: '流程设置',
                },
                {
                    title: '高级设置',
                },
            ],
            columns,
            list: [],
            approval_selection: {
                selectedRowKeys: [],
                onChange: (selectedRowKeys) => {
                    this.approval_selection.selectedRowKeys = selectedRowKeys;
                }
            },
            form_data: {
                list: [
                    {
                        type: 'text',
                        name: 'name',
                        title: '审批流程名称',
                        options: {
                            rules: [
                                {
                                    required: true,
                                    message: "请输入审批流程名称"
                                }
                            ]
                        },
                    },
                ]
            },
            form_data_advanced: {
                list: [
                    {
                        type: 'select',
                        name: 'repeat',
                        title: '审批人去重',
                        hidden: true,
                        options: {
                            initialValue: 'not'
                        },
                        list: [
                            {
                                key: 'first',
                                value: '保留第一个'
                            },
                            {
                                key: 'end',
                                value: '保留最后一个'
                            },
                            {
                                key: 'not',
                                value: '不做处理'
                            }
                        ]
                    },
                    {
                        type: 'select',
                        name: 'cc_notice',
                        title: '抄送通知',
                        options: {
                            initialValue: 'pre'
                        },

                        list: [
                            {
                                key: 'pre',
                                value: '发起审批时通知'
                            },
                            {
                                key: 'after',
                                value: '通过后通知'
                            },
                            {
                                key: 'all',
                                value: '都要通知'
                            }
                        ]
                    },
                    {
                        type: 'radio',
                        name: 'comments',
                        title: '审批意见',
                        options: {
                            initialValue: 'refuse'
                        },
                        list: [
                            {
                                key: 'refuse',
                                value: '拒绝必填'
                            },
                            {
                                key: 'all',
                                value: '同意、拒绝都必填'
                            }
                        ]
                    },
                    {
                        type: 'checkbox',
                        name: 'autograph',
                        title: '手写签名',
                        options: {
                            initialValue: []
                        },
                        list: [
                            {
                                key: 'launch',
                                value: '发起审批时必须填写手写签名'
                            },
                            {
                                key: 'approval',
                                value: '处理审批时必须填写手写签名'
                            }
                        ]
                    },
                    {
                        type: 'radio',
                        name: 'unusual',
                        title: '异常处理',
                        options: {
                            initialValue: 'skip'
                        },
                        relation_name: 'appoint_id',
                        list: [
                            {
                                key: 'skip',
                                value: '自动同意'
                            },
                            {
                                key: 'appoint',
                                value: '指定处理人'
                            }
                        ]
                    },
                    {
                        type: 'slot',
                        name: 'appoint_id',
                        close: true,
                        title: '处理人',
                        options: {
                        },

                    },
					{
					    type: "slot",
					    name: "rule_user",
					    title: "",
					    options: {},
					},
                ]
            },
            approval_type_list: [
                { label: '指定上级', value: 'director' },
                { label: '指定成员', value: 'appoint' },
                { label: '发起人自选', value: 'optional' },
                { label: '指定角色', value: 'role' },
            ],
            approval_type_value: 'director',
            approval_optional_type_list: [
                { label: '全体员工', value: 'all' },
                { label: '指定成员', value: 'user' },
                { label: '指定角色', value: 'role' },
            ],
            approval_optional_type_list_value: 'all',
            approval_manner_list: [
                { label: '依次审批', value: 'order', info: '(按先后顺序一人同意才流转到下一人)' },
                { label: '会签审批', value: 'jointly', info: '(需所有人同意)' },
                { label: '或签审批', value: 'or', info: '(1人同意即可)' },
            ],
            approval_manner_list_value: 'order',
            cc_data: {
                user_data: [

                ],
                director: {

                },
                appoint: {

                },
                role: {

                }
            },
            approval: [
                // {
                //     type: 'director',
                //     level: 1
                // },
                // {
                //     type: 'appoint',
                //     mode: 'or',
                //     user_ids: '',
                // },
                // {
                //     type: 'optional',
                //     mode: 'jointly',
                //     range: {
                //         type: 'all',
                //         appoint: ''
                //     }
                // },
                // {
                //     type: 'role',
                //     mode: 'jointly',
                //     role_id: ''
                // },
            ],
            stop_index: 0,
            stop_approval_index: 0,
            selectd_user: [],
            role_list: [],
            role_list_value: [],
            principal_level_value: 1,
        }
    },
    async created() {
        this.$method.get_role().then(res => {
            this.role_list = res;
        });
        this.get_data()
            formList({
            }).then(res => {
                this.list = res.data.list;
            })
		
		// authInfo().then(res=>{
			// this.treeData = this.$method.get_user_department_list(res.data.role);
			
		// })
    },
    computed: {
        selectd_user_text() {
            let text = '';
            if (this.selectd_user.length) {
                for (let index = 0; index < this.selectd_user.length; index++) {
                    if (index <= 2) {
                        text += this.selectd_user[index].username + (index < 2 ? (this.selectd_user.length - 1 != index ? '、' : ' ') : '...等 ')
                    }

                }
            }
            return text
        },
        principal_level() {
            let list = [];
            for (let i = 1; i <= 7; i++) {
                list.push({
                    type: 'director',
                    level: i,
                    label: i == 1 ? '直接上级' : `第${i}级上级`
                })
            }
            return list
        },
        get_selectd_role() {
            return this.role_list.filter(res => {
                return this.role_list_value.indexOf(res.id) > -1
            }).map(res => {
                return {
                    username: res.name,
                    id: res.id,
                    type: 'role'
                }
            });
        }
    },
    methods: {
        get_info(){
            getFlowInfo({
            	data: {
            		id: this.$route.query.id
            	}
            }).then(res => {
            	let data = res.data.data;
            	this.approval_selection.selectedRowKeys = data.approval;
            	this.form_data.list[0].options.initialValue = data.name;
            
            	this.$refs.form.setFieldsValue({
            		name: data.name
            	})
            
            	this.approval = data.flow.approval;
            	let user_data = []
            
            	data.flow.cc.forEach(item => {
            		user_data = user_data.concat(item.user_data)
            	})
            	// cc_data.user_data
            	this.cc_data.user_data = user_data
            
            	if (data.appoint_id_user) {
            		this.handler_user = data.appoint_id_user
            	}
            	this.form_data_advanced.list = this.form_data_advanced.list.map(item => {
            		if (item.type == 'checkbox') {
            			this.$refs.form_advanced.setFieldsValue({
            				[item.name]: data[item.name].split(',')
            			})
            		} else {
            			this.$refs.form_advanced.setFieldsValue({
            				[item.name]: data[item.name]
            			})
            		}
            		if (item.name == 'appoint_id' && data[item.name] > 0) {
            			if (data['unusual'] == 'appoint') {
            				item.close = false;
            			}
            		}
            		if (item.name == 'rule_user') {
            			let treeDataValue = [];
                       
            			data[item.name].map(io => {
                            
            				treeDataValue.push("user_" + io.id)
            				io.key = "user_" + io.id;
            				io.title = io.username;
            				return io;
            			});
            			this.treeDataValue = JSON.parse(JSON.stringify(treeDataValue));
                      
                        this.$forceUpdate()
            			this.$refs.form_advanced.setFieldsValue({
            				[item.name]: treeDataValue.join(',')
            			})
            		}
                    this.$forceUpdate()
            		return item;
            	})
            })
        },
        get_data(){
            post_user().then(res => {
                this.treeData = this.$method.get_user_department_list(res.data.list)
                   if (this.$route.query.id) {
                      this.get_info()
                   }
            })
        },
		TransferTreeChange(value) {
		    this.$refs.form_advanced.setFieldsValue({
		        rule_user: value.join(',')
		    });
		},
        // 添加审批人
        add() {
            this.clear_approval_selectd()
            this.visible = true;
            this.visible_index = -1
        },
        // 显示抄送人弹窗
        add_cc() {
            this.clear_approval_selectd()
            this.principal_level_value = ''
            let { level, user_ids, role_id } = this.get_cc_data()
            this.principal_level_value = level.length ? level[0].level || '' : '';
            this.selectd_user = user_ids;
            this.role_list_value = role_id.map(item => item.id)

            this.visible_cc = true;

        },
        // 显示选择用户弹窗
        select_user() {
            this.$refs.select_user.selectedRowKeys = this.selectd_user.map(item => item.id);
			this.$refs.select_user.select_user_list = this.selectd_user;
            this.select_user_visible = true;
        },

        role_list_change(value) {
            
        },
        // 确认已选用户
        add_user_submit({ data }) {
            this.selectd_user = data;
        },

        // 确认添加编辑审批人
        enter_add_approval() {
            let type = this.approval_type_value
            let data = {
                type,
            }

            // 指定上级
            if (type == 'director') {
                data['level'] = this.principal_level_value;
                data['user_data'] = this.principal_level.filter(res => {
                    return this.principal_level_value == res.level
                }).map(res => {
                    return {
                        type: 'director',
                        username: res.label
                    }
                });
            } else {
                data['mode'] = this.approval_manner_list_value;
            }

            // 指定成员
            if (type == 'appoint') {
                data['user_ids'] = this.selectd_user.map(item => item.id).join(',')
                if (!data['user_ids']) {
                    this.$message.error('请选择成员');
                    return
                }
                data['user_data'] = this.selectd_user.map(user => {
                    return {
                        username: user.username,
                        id: user.id,
                        type: 'appoint'
                    }
                });;
            }
            // 指定角色
            else if (type == 'role') {
                data['role_id'] = this.role_list_value.join(',')
                if (!data['role_id']) {
                    this.$message.error('请选择角色');
                    return
                }
                data['user_data'] = this.get_selectd_role
            }
            // 发起人自选
            else if (type == 'optional') {
                let range_type = this.approval_optional_type_list_value
                let range = {
                    type: range_type,
                }
                // 自选范围 全部
                if (range_type == 'all') {
                    data['user_data'] = [{
                        username: '全体成员'
                    }]
                }
                // 指定角色
                else if (range_type == 'role') {
                    range['appoint'] = this.role_list_value.join(',')
                    if (!range['appoint']) {
                        this.$message.error('请选择角色');
                        return
                    }
                    data['user_data'] = this.get_selectd_role
                }
                // 指定成员
                else if (range_type == 'user') {
                    range['appoint'] = this.selectd_user.map(item => item.id).join(',')
                    if (!range['appoint']) {
                        this.$message.error('请选择成员');
                        return
                    }
                    data['user_data'] = this.selectd_user.map(user => {
                        return {
                            username: user.username,
                            id: user.id,
                            type: 'appoint'
                        }
                    });
                }
                data['range'] = range;
            }

            // 编辑审批人
            if (this.visible_index > -1) {
                this.approval[this.visible_index] = data
                this.approval = [...this.approval];
            }
            // 添加审批人
            else {
                this.approval.push(data)
            }

            this.visible = false;
        },
        // 添加编辑 确认审批人
        edit_approval(item, index) {
            this.visible = true;
            this.visible_index = index
            this.clear_approval_selectd()
            this.approval_type_value = item.type;
            if (item.type !== 'director') {
                this.approval_manner_list_value = item.mode
            }
            if (item.type === 'director') {
                this.principal_level_value = item.level;
            } else if (item.type === 'appoint') {
                this.selectd_user = item.user_data
            } else if (item.type === 'role') {
                this.role_list_value = item.role_id.split(',').map(item => parseInt(item));
            } else if (item.type === 'optional') {
                this.approval_optional_type_list_value = item.range.type;
                if (item.range.type == 'user') {
                    this.selectd_user = item.user_data;
                } else if (item.range.type == 'role') {
                    this.role_list_value = item.range.appoint.split(',').map(item => parseInt(item));
                }
            }
        },
        // 清除已选的数据
        clear_approval_selectd() {
            this.approval_type_value = 'director'
            this.approval_optional_type_list_value = 'all'
            this.selectd_user = [];
            this.role_list_value = []
            this.principal_level_value = 1
        },
        // 删除审批流
        delete_approval(item, index) {
            this.approval.splice(index, 1)
        },

        // 审批人模式被改变
        approval_type_change(value) {
            if ((this.approval_type_value == 'optional' || this.approval_type_value == 'role') && this.approval_manner_list_value == 'order') {
                this.approval_manner_list_value = 'jointly'
            }
        },

        // 确认抄送人
        enter_add_cc() {
            let user_data = [];
            // for (let i = 1; i <= this.principal_level_value; i++) {
            //     user_data.push({
            //         username: i == 1 ? '直接上级' : `第${i}级上级`,
            //         type: 'director'
            //     })
            // }
			if(this.principal_level_value){
				user_data.push({
					username: this.principal_level_value == 1 ? '直接上级' : `第${this.principal_level_value}级上级`,
					level: this.principal_level_value,
					type: 'director'
				})
			}
			
            user_data = user_data.concat(this.get_selectd_role)
            user_data = user_data.concat(this.selectd_user.map(user => {
                return {
                    username: user.username,
                    id: user.id,
                    type: 'appoint'
                }
            }))

            this.cc_data.user_data = user_data;
            this.visible_cc = false;
        },

        prev() {
            if (this.steps_current !== 0) {
                this.steps_current--;
            }
        },

        get_cc_data() {
            let level = [],
                user_ids = [],
                role_id = [];
            this.cc_data.user_data.forEach(item => {
                if (item.type == 'director') {
                    level.push(item)
                } else if (item.type == 'appoint') {
                    user_ids.push(item)
                } else if (item.type == 'role') {
                    role_id.push(item)
                }
            })

            return { level, user_ids, role_id }
        },

        next() {
            if (this.steps_current == 0) {
                this.$refs.form.submit().then(res => {
                    this.steps_current++;
                })
                return
            } else if (this.steps_current == 1) {
                if (this.approval.length == 0) {
                    this.$message.error('请选择审批人');
                    return
                }
                this.steps_current++
                return
            } else if (this.steps_current == 2) {
                let { level, user_ids, role_id } = this.get_cc_data()
                let flow = {
                    approval: this.approval,
                    cc: [
                        {
                            type: 'director',
                            level: level.length,
                            user_data: level,
                        },
                        {
                            type: 'appoint',
                            user_ids: user_ids.map(item => item.id).join(','),
                            user_data: user_ids,
                        },
                        {
                            type: 'role',
                            role_id: role_id.map(item => item.id).join(','),
                            user_data: role_id,
                        },
                    ]
                };

                Promise.all([this.$refs.form.submit(), this.$refs.form_advanced.submit()]).then(result => {
					result[1].autograph=result[1].autograph.join(',')
                    let data = {
                        approval: this.approval_selection.selectedRowKeys.join(','),
                        flag: this.approval_selection.selectedRowKeys,
                        ...result[0],
                        ...result[1],

                        flow: flow
                    };

                    if (this.handler_user.id) {
                        data['appoint_id'] = this.handler_user.id
                    }
                    if (this.$route.query.id) {
                        data['id'] = this.$route.query.id;
                    }
                    addFlow({
                        data,
                    }).then(res => {
                        this.$router.back();
                    })
                })
            }
        },
        handleChange({ e, label }) {
            if (e.target.name == 'unusual') {
                this.form_data_advanced.list = this.form_data_advanced.list.map(item => {
                    if (item.name == 'appoint_id') {
                        if (e.target.value == 'appoint') {
                            item.close = false;
                        } else {
                            item.close = true;
                        }
                    }
                    return item
                })
            }
        },

        add_handler_user_submit({ data }) {
            this.handler_user = data;
        },

        select_handler() {
            this.$refs.select_handler.selectedRowKeys = [this.handler_user.id]
            this.select_handler_user_visible = true;
        },

        // 删除抄送人
        del_cc_user(item, index) {
            this.cc_data.user_data.splice(index, 1)
        },
        dragstart(e, ind) {
            // e.preventDefault()
        },
        drop(e) {
            e.preventDefault()
        },
        // 拖拽位置
        dragenter(e, ind, index) {
            e.preventDefault()
            if (ind != -1) {
                this.stop_index = ind;
            }
            this.stop_approval_index = index
        },
        dragover(e) {
            e.preventDefault()
        },
        // 拖拽完成
        dragend(e, ind, index) {
            if (this.stop_approval_index == index) {
                if (ind != this.stop_index && ind != -1) {
                    let list = this.approval[index].user_data;
                    let move_data = list[ind];
                    list.splice(ind, 1)
                    list.splice(this.stop_index, 0, move_data)
                    this.approval[index].user_data = [...list];
                }
            } else {
                let list = this.approval;
                let move_data = list[index];
                list.splice(index, 1)
                list.splice(this.stop_approval_index, 0, move_data)
                this.approval = list;
            }

        },
        handleClose() {
            this.alert_visible = false;
        }
    }
};
</script>
<style lang="less" scoped>
.steps {
    max-width: 700px;
    margin: 30px auto 0;
    .alert {
        margin: 40px auto 0;
        width: 509px;
    }
}
.time_line {
    width: 520px;
    margin: 42px auto 0;
    .li {
        &.last_li {
            padding-bottom: 30px;
        }
        &.li_add {
            padding-bottom: 0px;
        }
        .item {
            position: relative;
            z-index: 1;

            &.item_add {
                display: flex;
                transform: translateY(-10px);
            }
            .tit {
                color: #333;
                display: flex;
                line-height: 24px;
            }
            .icon {
                cursor: pointer;
                padding: 0 5px;
                display: none;
                &.drag {
                    display: block;
                }
            }
        }
        &:hover {
            .item {
                .icon {
                    display: block;
                }
            }
        }
    }
}
.item_ul {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    .avatar {
        margin: 16px 10px 0;
        width: 80px;
        position: relative;
        z-index: 1;
        &.add {
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 0;
        }
    }
}
.select_pop {
    margin: 9px;
    border: 1px solid #e4e5e7;
    border-radius: 5px;
    .title {
        height: 36px;
        line-height: 36px;
        background-color: #f5f6f7;
        font-size: 14px;
        color: #333;
        box-sizing: border-box;
        padding-left: 15px;
    }
    .h2 {
        font-size: 14px;
        color: #333;
        margin-bottom: 0px;
    }

    .content {
        padding: 16px;
    }
}
.h2_info {
    color: #999;
}
.radio {
    margin: 9px 8px 9px 0px;
    &.block {
        display: block;
    }
}
.select {
    width: 100%;
    margin-top: 8px;
}
.select_btn {
    margin-top: 10px;
}
.selectd_user {
    margin-top: 10px;
}
.tit_info {
    color: #999;
}
.box_form {
    width: 600px;
    margin: 0px auto 0;
}
.h1 {
    width: 520px;
    margin: 0px auto 0;
    .title {
        font-size: 16px;
        font-weight: bold;
        color: #333;
        padding-top: 40px;
    }
    .add {
        padding-top: 15px;
    }
}
.approval_title {
    margin-top: 40px;
}
.box_form_btns {
    text-align: center;
    padding: 40px 0;
    .btn {
        width: 74px;
    }
}
.rule_user_select{
	padding-top: 10px;
	.title{
		padding-top: 0px;
		margin-bottom: 12px;
	}
}
</style>
